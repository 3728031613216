import React from "react";
import { graphql, PageProps } from "gatsby";
import Layout from "../layout";
import Container from "../components/Container";

export const query = graphql`
  query ProjectQuery($contentful_id: String!) {
    data: contentfulProject(contentful_id: { eq: $contentful_id }) {
      title
      link
      image {
        fluid {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      content {
        raw
      }
      metadata {
        ...Metadata
      }
    }
  }
`;

const ProjectTemplate: React.FC<PageProps> = ({}) => {
  return (
    <Layout>
      <Container width="narrow"></Container>
    </Layout>
  );
};

export default ProjectTemplate;
